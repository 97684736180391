<template>
  <div class="mx-auto">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form ref="form">
      <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
        <div class="custom-title pt-8 pb-4">Document Intake</div>
        <p class="mb-8">
          If you have already received documents from your client, you may want
          to upload them here. This will help you and your client to keep the
          documents of the case in order and in one central location.
        </p>
        <div class="custom-subtitle mb-4 mt-10">Upload Lease</div>
        <DocumentUploadLease :uploadedFiles="leaseDocuments" />

        <div class="custom-subtitle mb-4 mt-10">Upload Ledger</div>
        <DocumentUploadLedger :uploadedFiles="ledgerDocuments" />

        <div class="custom-subtitle mb-4 mt-10">
          Upload Landlord Registration
        </div>
        <DocumentUploadLandlord :uploadedFiles="landlordDocuments" />

        <div class="mt-10 d-flex">
          <span class="custom-subtitle">Other</span>
          <v-text-field
            v-model="otherDocName"
            ref="input"
            label="Insert Document Name"
            rounded
            solo
            flat
            dense
            background-color="#F0F5F6"
            class="ml-4 pb-2"
          />
        </div>
        <DocumentUploadOther
          :valid="otherDocValid"
          :documentName="otherDocName"
          :uploadedFiles="otherDocuments"
          @uploaded="uploaded"
        />
        <v-row>
          <div class="d-flex align-center ml-auto">
            <v-btn
              class="btn--plain"
              color="accent"
              icon
              tile
              @click="addAdditionalDocs"
            >
              <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>

      <v-card-actions
        style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
        class="justify-space-between py-4 px-12 mt-12"
      >
        <v-btn
          rounded
          color="accent_light"
          class="px-8 mb-2 mt-4 white--text capital--btn"
          depressed
          @click="$router.push({ name: 'vc-suit-summary' })"
        >
          skip
        </v-btn>
        <div>
          <v-btn
            rounded
            color="accent_light"
            class="mb-2 mt-4 btn--plain capital--btn"
            text
            @click="back"
          >
            go back
          </v-btn>
          <v-btn
            rounded
            color="accent_light"
            class="px-8 mb-2 mt-4 white--text capital--btn"
            depressed
            @click="next"
          >
            continue
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import DocumentUploadLease from "../../DocumentUploadLease.vue";
import DocumentUploadLedger from "../../DocumentUploadLedger.vue";
import DocumentUploadLandlord from "../../DocumentUploadLandlord.vue";
import DocumentUploadOther from "../../DocumentUploadOther.vue";
export default {
  components: {
    DocumentUploadLease,
    DocumentUploadLedger,
    DocumentUploadLandlord,
    DocumentUploadOther,
  },
  data() {
    return {
      otherDocName: "",
    };
  },
  computed: {
    case() {
      return this.$store.getters["eviction/currentCase"];
    },
    leaseDocuments() {
      const documents = this.case.documents || [];
      return documents.filter((item) => item.name == "Lease");
    },
    ledgerDocuments() {
      const documents = this.case.documents || [];
      return documents.filter((item) => item.name == "Ledger");
    },
    landlordDocuments() {
      const documents = this.case.documents || [];
      return documents.filter((item) => item.name == "Landlord Registration");
    },
    otherDocuments() {
      const documents = this.case.documents || [];
      return documents.filter(
        (item) =>
          item.name !== "Lease" &&
          item.name !== "Ledger" &&
          item.name !== "Landlord Registration"
      );
    },
    otherDocValid() {
      return this.otherDocName !== "";
    },
  },
  async activated() {
    window.scrollTo(0, 0);
    await this.$store.dispatch("eviction/loadDocuments", {
      caseId: this.case.id,
      name: "",
    });
  },
  methods: {
    addAdditionalDocs() {
      this.$refs.input.focus();
    },
    uploaded() {
      this.otherDocName = "";
    },
    async saveData() {
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {},
        submitted: false,
        logMessage: "DocumentIntake.vue:138",
        status: "vc-documentIntake",
      });
    },
    next() {
      this.saveData();
      this.$router.push({
        name: `${this.$route.name.includes("vc") ? "vc-" : ""}suit-summary`,
      });
    },
    back() {
      this.$router.push({
        name: this.currentCase.clrsDraft
          ? "vc-registration"
          : "vc-certification",
      });
    },
  },
};
</script>

<style scoped>
.dropZone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #fff;
}

.dropZone:hover {
  border: 2px dashed #eee;
}

.dropZone-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #fff;
}
.dropZone-uploaded:hover {
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}
</style>

<style lang="scss">
.document-intake {
  &__form {
    // width: 70%;

    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 24px auto;
    }
  }
}
</style>
