import { render, staticRenderFns } from "./Tenants.vue?vue&type=template&id=a648b6ce&"
import script from "./Tenants.vue?vue&type=script&lang=js&"
export * from "./Tenants.vue?vue&type=script&lang=js&"
import style0 from "./Tenants.vue?vue&type=style&index=0&id=a648b6ce&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports