<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Additional Conditions</div>
      <div class="pb-4">
        Provide here any additional conditions the parties agreed to, as well as
        the date by which the party must comply. You can enter more than one
        condition if there are different compliance dates.
      </div>

      <v-form ref="formDate" class="px-8 pt-10 pb-4 mx-auto text-left">
        <div v-for="(condition, index) in conditions" :key="index">
          <v-row class="display-flex align-center mb-4">
            <v-expansion-panels>
              <v-col cols="10">
                <v-expansion-panel class="secondary--text">
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-down"
                    class="font-weight-medium pl-8 py-8"
                    style="
                      box-shadow: 15px 15px 40px #00000029;
                      border-radius: 5px;
                    "
                  >
                    Condition {{ index + 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="mt-8 mb-4">
                    <span class="font-weight-medium">Terms</span>
                    <v-textarea
                      v-model="condition.term"
                      rounded
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                    />
                    <v-row class="d-flex justify-space-around">
                      <v-col
                        class="font-weight-medium secondary--text pb-2 d-flex flex-column"
                        cols="7"
                      >
                        <div class="d-flex">
                          <span>Compliance Date</span>
                          <v-btn
                            rounded
                            color="accent_light"
                            class="ml-auto btn--plain capital--btn"
                            text
                            @click="clearComplianceDate(index)"
                          >
                            Clear
                          </v-btn>
                        </div>
                        <v-menu
                          v-model="condition.menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="condition.complianceDate"
                              append-icon="$calendar"
                              color="accent"
                              readonly
                              rounded
                              solo
                              flat
                              dense
                              background-color="#F0F5F6"
                              v-on="on"
                              @click:append="condition.menu = true"
                            />
                          </template>
                          <v-date-picker
                            v-model="condition.selectedComplianceDate"
                            no-title
                            :show-current="false"
                            color="primary"
                            class="custom-date"
                            @input="
                              closedComplianceDateMenu(
                                condition.selectedComplianceDate,
                                index
                              )
                            "
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        class="font-weight-medium secondary--text pl-2 pb-2 d-flex flex-column"
                        cols="5"
                      >
                        <div style="margin-bottom: 6px">Party Responsible</div>
                        <v-select
                          v-model="condition.partyResponsible"
                          background-color="#F0F5F6"
                          item-color="#F6F9FA"
                          rounded
                          solo
                          flat
                          dense
                          :items="['Tenant', 'Landlord']"
                        >
                          <v-icon slot="append" medium color="accent">
                            mdi-chevron-down
                          </v-icon>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>
              <v-col cols="1">
                <v-icon
                  class="mt-6"
                  v-if="conditions.length > 1"
                  size="xx-large"
                  @click="deleteCondition(index)"
                  >mdi-close</v-icon
                >
              </v-col>
              <v-col cols="1">
                <v-icon
                  class="mt-4"
                  v-if="index == conditions.length - 1"
                  size="xxx-large"
                  color="primary"
                  @click="addCondition(index)"
                  >mdi-plus-circle</v-icon
                >
              </v-col>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-form>

      <div class="pb-4">
        <span class="font-weight-medium"
          >Select this sentence if you want it to be part of the Consent
          Agreement:</span
        >
        <v-row class="mt-2 mb-2 ml-1">
          <v-col cols="1" class="pa-0">
            <v-checkbox
              v-model="consentAgreement"
              color="primary"
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-checkbox-blank-circle"
              class="mt-1"
            />
          </v-col>
          <v-col cols="11" class="d-flex pa-0">
            <p class="secondary--text mt-1">
              {{ consentAgreementText }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-space-between py-4 px-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        @click="next"
      >
        skip
      </v-btn>
      <div>
        <v-btn
          rounded
          color="accent_light"
          class="mb-2 mt-4 btn--plain capital--btn"
          text
          @click="goBack"
        >
          Go Back
        </v-btn>
        <v-btn
          rounded
          color="accent_light"
          class="px-8 mb-2 mt-4 white--text capital--btn"
          depressed
          :disabled="!valid"
          @click="next"
        >
          Continue
        </v-btn>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import { courtOutcomesString, trialActionsString } from "@/utils/worFlowData";
import { mapGetters } from "vuex";

export default {
  name: "AdditionalConditions",
  data() {
    return {
      valid: true,
      conditions: null,
      consentAgreement: false,
      consentAgreementText: "",
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    isValid() {
      return this.valid;
    },
    showTerm() {
      return (
        !this.$route.name.includes("trial") &&
        (this.currentCase.courtOutcome === courtOutcomesString()[3] ||
          this.currentCase.courtOutcome === courtOutcomesString()[4])
      );
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.conditions = this.currentCase.additionalConditions;
    if (this.currentCase.additionalConditions === null)
      this.conditions = [
        {
          term: "",
          complianceDate: null,
          selectedComplianceDate: null,
          menu: false,
          partyResponsible: null,
        },
      ];
    this.consentAgreementText = `The Tenant shall remove all belongings from the property on or before the Move Out Date. Tenant further agrees that the Landlord shall have the right to dispose of any items remaining in the property after the Move Out Date.`;
    if (this.currentCase.courtOutcome === courtOutcomesString()[2])
      // Executed Consent to Enter Judgment - Tenant Pays and Stays
      this.consentAgreementText = `If the Tenant is evicted as permitted by law due to a breach of this Agreement, the Tenant shall remove all belongings from the property within 3 business days of receipt of the Warrant of Removal. Tenant further agrees that the Landlord shall have the right to dispose of any items remaining in the property after such date.`;
    if (this.currentCase.courtOutcome === courtOutcomesString()[3])
      // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
      this.consentAgreementText = `The Tenant shall remove all belongings from the property no later than the Move Out Date, or if a breach of payment occurs, within 3 business days of receipt of the Warrant of Removal. Tenant further agrees that the Landlord shall have the right to dispose of any items remaining in the property after such date.`;
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async saveData() {
      const conditions = this.conditions;
      this.conditions.map((item, index) => {
        if (
          item.term === "" ||
          item.complianceDate === null ||
          item.partyResponsible === null
        ) {
          conditions.splice(index, 1);
        }
      });
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          additionalConditions: conditions.length ? conditions : null,
          consentAgreement: this.consentAgreement,
          consentAgreementText: this.consentAgreementText,
        },
        status: this.$route.name.includes("trial")
          ? "wor-additionalConditions"
          : "co-additionalConditions",
      });
    },
    addCondition() {
      this.conditions.push({
        term: "",
        complianceDate: null,
        selectedComplianceDate: null,
        menu: false,
        partyResponsible: null,
      });
    },
    next() {
      if (this.$route.name.includes("trial")) {
        this.$router.push({ name: "wor-trial-signature" });
      } else {
        this.$router.push({ name: "wor-signature" });
      }
    },
    goBack() {
      if (this.$route.name.includes("trial")) {
        if (this.currentCase.trialAction === trialActionsString()[6]) {
          // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
          this.$router.push({ name: "wor-trial-payment-plan" });
        } else {
          this.$router.push({ name: "wor-trial-rent-consent-term" });
        }
      } else {
        if (this.currentCase.courtOutcome === courtOutcomesString()[2]) {
          this.$router.push({ name: "wor-rent-consent-term" });
        } else if (this.currentCase.courtOutcome === courtOutcomesString()[4]) {
          this.$router.push({ name: "wor-move-out-date" });
        } else {
          this.$router.push({ name: "wor-payment-plan" });
        }
      }
    },
    closedComplianceDateMenu(date, index) {
      this.conditions[index].menu = false;
      this.conditions[index].selectedComplianceDate = date;
      this.conditions[index].complianceDate =
        this.$dayjs(date).format("MMMM DD, YYYY");
    },
    deleteCondition(index) {
      this.$delete(this.conditions, index);
    },
    clearComplianceDate(index) {
      this.conditions[index].complianceDate = "";
      this.conditions[index].selectedComplianceDate = null;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
