<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Rent Ledger</div>
      <div class="pb-4">
        We created a ledger for the terms that you entered in the verified
        complaint. This ledger will need to be updated as the matter goes
        forward.
      </div>
      <div class="d-flex align-center mt-3 mb-3">
        <div class="ml-1 info--text text-uppercase">
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            @click="addPayDialogOpen = true"
          >
            <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          Add Payments
        </div>
      </div>
      <div style="max-height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            :headers="headers"
            :items="ledgerData"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.date="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                props.item.date
              }}</span>
            </template>
            <template v-slot:item.details="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                props.item.details
              }}</span>
            </template>
            <template v-slot:item.amount="props">
              <div class="d-flex">
                <div :class="props.item.nextMonth ? 'font-weight-bold' : ''">
                  {{
                    !props.item.amount
                      ? ""
                      : convertToCurrency(props.item.amount)
                  }}
                </div>
              </div>
            </template>
            <template v-slot:item.payment="props">
              <div class="d-flex">
                <v-edit-dialog
                  v-if="props.item.payment"
                  large
                  persistent
                  @save="savePayAmount()"
                >
                  <div :class="props.item.nextMonth ? 'font-weight-bold' : ''">
                    {{ convertToCurrency(0 - parseFloat(props.item.payment)) }}
                    <v-icon size="14" color="#4dccc4" class="ml-1">
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4">Change amount</div>
                    <v-text-field
                      v-model="props.item.payment"
                      label="Edit"
                      single-line
                      autofocus
                      type="number"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <v-icon
                  v-if="props.item.payment"
                  size="18"
                  color="#4dccc4"
                  class="ml-1 mt-1"
                  @click="clearPayAmount(props.item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:item.totalBalance="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                convertToCurrency(props.item.totalBalance)
              }}</span>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>

      <h2 class="text-right mt-3">
        Total due: {{ convertToCurrency(totalDue) }}
      </h2>

      <div class="mt-8 mb-4">
        <v-row>
          <v-col cols="3">Total Base Rent</v-col>
          <v-col cols="2">Late Fees</v-col>
          <v-col cols="2">Court Fees</v-col>
          <v-col cols="2">Attorney Fees</v-col>
          <v-col cols="3">Total Balance</v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col cols="3">{{ convertToCurrency(totalBaseRent) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(lateFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(courtFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(attorneyFee) }}</v-col>
          <v-col cols="3">{{ convertToCurrency(totalDue) }}</v-col>
        </v-row>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-space-between py-4 px-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        @click="$router.push({ name: 'wor-trial-confirm-dates' })"
      >
        skip
      </v-btn>
      <div>
        <v-btn
          rounded
          color="accent_light"
          class="mb-2 mt-4 btn--plain capital--btn"
          text
          @click="goBack"
        >
          Go Back
        </v-btn>
        <v-btn
          rounded
          color="accent_light"
          class="px-8 mb-2 mt-4 white--text capital--btn"
          depressed
          @click="next"
        >
          Continue
        </v-btn>
      </div>
    </v-card-actions>

    <div>
      <v-dialog
        v-model="addPayDialogOpen"
        value="''"
        max-width="1000"
        persistent
      >
        <AddPayDialog
          @close:dialog="addPayDialogOpen = false"
          @add="addPayment"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AddPayDialog from "@/components/dialogs/AddPayDialog";
import { trialActionsString } from "@/utils/worFlowData";
import { mapGetters } from "vuex";

export default {
  name: "RentLedger",
  components: {
    AddPayDialog,
  },
  data() {
    return {
      addPayDialogOpen: false,
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        { text: "Details", value: "details" },
        { text: "Amount Due", value: "amount" },
        { text: "Amount Paid", value: "payment" },
        { text: "Total Balance", value: "totalBalance" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
      ledgerData: [],
      charges: [],
      payments: [],
      totalBaseRent: 0,
      attorneyFee: 0,
      courtFee: 0,
      lateFee: 0,
      totalDue: 0,
      nextMonthRent: {},
      useNext: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.useNext = false;
    /* add data to table - START */
    this.charges = [];
    let chargeItem;

    const pastDueRent = this.currentCase.pastDueRent.filter(
      (value) => value.selected
    );
    for (const element of pastDueRent) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.timePeriod).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = "Rent";
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
      if (pastDueRent.indexOf(element) === 0) {
        this.nextMonthRent = {
          ...chargeItem,
          date: this.$dayjs(element.timePeriod)
            .add(1, "month")
            .format("MM/DD/YYYY"),
          dateForSort: this.$dayjs(element.timePeriod)
            .add(1, "month")
            .format("YYYY-MM-DD"),
          nextMonth: true,
        };
      }
    }

    for (const element of this.currentCase.lateCharges.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort = element.timePeriod;
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
      this.lateFee += chargeItem.amount;
    }

    for (const element of this.currentCase.utilities.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort = element.timePeriod;
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    for (const element of this.currentCase.fees.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort = element.timePeriod;
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }
    this.payments = this.currentCase.pastDueRentPayments || [];

    this.applyChangeInCharge();
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async saveData() {
      if (this.useNext) return;
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          pastDueRentPaid: this.totalDue,
          pastDueRentPayments: this.payments,
        },
        status: "wor-rentBalance",
      });
    },
    async next() {
      await this.saveData();
      this.useNext = true;
      this.$router.push({ name: "wor-trial-confirm-dates" });
    },
    goBack() {
      switch (this.currentCase.trialAction) {
        case trialActionsString()[0]: // Request for Warrant of Removal - Default Judgment
        case trialActionsString()[1]: // Request for Warrant of Removal - Judgment for Possession
          this.$router.push({ name: "wor-trial-action" });
          break;
        case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
          if (this.currentCase.breachTerms[1].selected)
            this.$router.push({ name: "wor-trial-breach-other-conditions" });
          else this.$router.push({ name: "wor-trial-breach-payment-plan" });
          break;
        case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          if (this.currentCase.breachTerms[2].selected)
            this.$router.push({ name: "wor-trial-breach-other-conditions" });
          else if (this.currentCase.breachTerms[1].selected)
            this.$router.push({ name: "wor-trial-breach-payment-plan" });
          else this.$router.push({ name: "wor-trial-move-out-date" });
          break;
        case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
          this.$router.push({ name: "wor-trial-move-out-date" });
          break;
      }
    },
    applyChangeInCharge() {
      this.payments = this.payments.map((item) => {
        return {
          ...item,
          dateForSort: this.$dayjs(item.date).format("YYYY-MM-DD"),
          amount: 0,
        };
      });
      this.ledgerData = [...this.charges, ...this.payments];
      const today = this.$dayjs().format("MM-DD");
      const rentDate = `${this.$dayjs(this.nextMonthRent.date).format("MM")}
                        -${
                          parseInt(this.currentCase.rentDueBy) > 9
                            ? this.currentCase.rentDueBy
                            : `0${this.currentCase.rentDueBy}`
                        }`;
      if (today > rentDate && !this.ledgerData.includes(this.nextMonthRent)) {
        this.ledgerData.push(this.nextMonthRent);
      }

      this.ledgerData.sort((a, b) =>
        a.dateForSort > b.dateForSort
          ? 1
          : b.dateForSort > a.dateForSort
          ? -1
          : 0
      );
      this.totalBaseRent = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      if (this.currentCase.legalFeesPermitted) {
        const attorneyFee = this.currentCase.legalFees.find(
          (item) => item.type === "Attorney Fee"
        );
        this.attorneyFee =
          attorneyFee && attorneyFee.selected ? attorneyFee.amount : 0;
        this.ledgerData.push({
          details: "Attorney Fees",
          amount: parseFloat(this.attorneyFee),
          payment: 0,
          totalBalance: 0,
        });
        this.courtFee = this.currentCase.legalFees
          .filter((item) => item.type !== "Attorney Fee")
          .reduce((result, item) => result + parseFloat(item.amount), 0);
        this.ledgerData.push({
          details: "Court Fees",
          amount: parseFloat(this.courtFee),
          payment: 0,
          totalBalance: 0,
        });
      }

      this.totalDue = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      const ledgerData = this.ledgerData.slice();
      for (let i = 0; i < ledgerData.length; i++) {
        if (i === 0) {
          ledgerData[i].totalBalance =
            ledgerData[i].amount - ledgerData[i].payment;
        } else {
          ledgerData[i].totalBalance =
            ledgerData[i - 1].totalBalance +
            ledgerData[i].amount -
            ledgerData[i].payment;
        }
      }
      this.ledgerData = ledgerData;
    },
    addPayment(newPayments) {
      this.payments = [...this.payments, ...newPayments];
      this.addPayDialogOpen = false;
      this.applyChangeInCharge();
    },
    savePayAmount() {
      this.applyChangeInCharge();
    },
    clearPayAmount(item) {
      const index = this.payments.indexOf(item);
      this.payments.splice(index, 1);
      this.applyChangeInCharge();
    },
  },
};
</script>

<style scoped>
.currency {
  padding: 0 !important;
}
</style>
